import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

function LinkCount(props) {

  const [url, setUrl] = useState()
  const [target, setTarget] = useState()
  const [propsCopy, setPropsCopy] = useState()
  const location = useLocation();
  var clientname = location.pathname.toLowerCase();
  var client = clientname.replace("/", "");

  useEffect(() => {
    var propCopy = {...props}
    setPropsCopy(propCopy)

    if (propCopy.onClick) delete propCopy.onClick
    if (propCopy.rel) delete propCopy.rel

    if (propCopy.href) {
      setUrl(propCopy.href)
      delete propCopy.href
    }

    if (propCopy.target) {
      setTarget(propCopy.target)
      delete propCopy.target
    }
  }, [])

  function store() {
    if (props.onClick) {
      props.onClick();
    }


    fetch('https://untoldapi.nl/clickcount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url, origin: propsCopy.origin, clientname: client }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response;
      })
      .then((data) => {
        if(url && url.length) {
          if (!target || !target.length  || target === "_self") {
            console.log("self")
            window.location.href = url;
          } else {
            let windowFeatures = ""
            if (props.rel.indexOf("noopener") !== -1) {
              windowFeatures = "noopener"
            }

            if (props.rel.indexOf("noreferrer") !== -1) {
              windowFeatures = "noreferrer"
            }
            setTimeout(() => {
              window.open(url, target, windowFeatures)
            })
          }
        }

      })
      .catch((error) => {

        // console.error('There was a problem with the fetch operation:', error);
      });


  }

  return (
    <a
      onClick={store} {...propsCopy}
    >
      {props.children}
    </a>
  );
}

export default LinkCount;
