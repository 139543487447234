import React, { useContext }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsCategoriesParentCategories () {

    const { clientdata, setClientdata } = useContext(clientdatacontext);

    async function handleclickaddparentcategory() {
        var newparentcategory = document.getElementById("inputnewparentcategory").value
        document.getElementById("inputnewparentcategory").value = ''

        // const urlparentcategoriesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=parentcategories&actionparentcategoryaction=add&parentcategory=' + newparentcategory + '&apikey=' + clientdata.apikey)
        const urlparentcategoriesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=parentcategories&actionparentcategoryaction=add&parentcategory=' + newparentcategory + '&apikey=' + clientdata.apikey)
        var urlparentcategorieswithoutrecos = await urlparentcategoriesnojson.json();
             var urlparentcategories = {clientname: urlparentcategorieswithoutrecos.clientname, city: urlparentcategorieswithoutrecos.city, apikey: urlparentcategorieswithoutrecos.apikey, cityname: urlparentcategorieswithoutrecos.cityname, excludedlocations: urlparentcategorieswithoutrecos.excludedlocations, websiteChanges: urlparentcategorieswithoutrecos.websiteChanges, imageChanges: urlparentcategorieswithoutrecos.imageChanges, topChanges: urlparentcategorieswithoutrecos.topChanges, inlijstingChanges: urlparentcategorieswithoutrecos.inlijstingChanges, lengthRecos: urlparentcategorieswithoutrecos.lengthRecos, categories: urlparentcategorieswithoutrecos.categories, minimalrecos: urlparentcategorieswithoutrecos.minimalrecos, status: urlparentcategorieswithoutrecos.status, cityrecos: clientdata.cityrecos}
            setClientdata(urlparentcategories)
    }

    async function handleclickdeleteparentcategory(event) {
        var deleteparentcategory = event.target.id;

        // const urlparentcategoriesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=parentcategories&actionparentcategoryaction=delete&parentcategory=' + deleteparentcategory + '&apikey=' + clientdata.apikey)
        const urlparentcategoriesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=parentcategories&actionparentcategoryaction=delete&parentcategory=' + deleteparentcategory + '&apikey=' + clientdata.apikey)
            var urlparentcategorieswithoutrecos = await urlparentcategoriesnojson.json();
             var urlparentcategories = {clientname: urlparentcategorieswithoutrecos.clientname, city: urlparentcategorieswithoutrecos.city, apikey: urlparentcategorieswithoutrecos.apikey, cityname: urlparentcategorieswithoutrecos.cityname, excludedlocations: urlparentcategorieswithoutrecos.excludedlocations, websiteChanges: urlparentcategorieswithoutrecos.websiteChanges, imageChanges: urlparentcategorieswithoutrecos.imageChanges, topChanges: urlparentcategorieswithoutrecos.topChanges, inlijstingChanges: urlparentcategorieswithoutrecos.inlijstingChanges, lengthRecos: urlparentcategorieswithoutrecos.lengthRecos, categories: urlparentcategorieswithoutrecos.categories, minimalrecos: urlparentcategorieswithoutrecos.minimalrecos, status: urlparentcategorieswithoutrecos.status, cityrecos: clientdata.cityrecos}
            setClientdata(urlparentcategories)
    }




    return (
        <div className="login-recommendations-categories-categories-display-container">
    
            <div>
            <div className="login-recommendations-categories-categories-overview-header" style={{marginBottom:50}}>Parentcategories</div>
            <div className="login-recommendations-categories-parentcategories-overview-add-remove-header">add</div>
            <input id="inputnewparentcategory"></input>
            <div className="login-recommendations-categories-parentcategories-overview-add-button" onClick={handleclickaddparentcategory}>add</div>
            </div>


            <div className="login-recommendations-categories-parentcategories-overview-add-remove-header">remove</div>
            {clientdata.categories.map((item, index)=>(
                <div key={index} className="login-recommendations-categories-categories-parentcategory-container" style={{display: "inline-block"}}>
                <div key={index} className="login-recommendations-categories-categories-parentcategory" id={item.parentcategory} onClick={handleclickdeleteparentcategory}>
                {item.parentcategory}
                </div>
                </div>
            ))}    
            </div>
)
}

export default RecommendationsCategoriesParentCategories
