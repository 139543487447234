import React, { useState } from "react";
import './CSS/Home.css';
import './CSS/Dashboard.css';
import './CSS/Styles.css';
import './CSS/Recommendations.css';

function Home () {



const [username, setUsername] = useState("")
const [password, setPassword] = useState("")
const [tokendeleted, setTokendeleted] = useState(false)

if (tokendeleted === false ){
    localStorage.removeItem('token');
    setTokendeleted(true);
}

async function clientlogininfo (){
    // const response = await fetch('https://untoldapi.nl/login',  {
        const response = await fetch('https://untoldapi.nl/login',  {
            // const response = await fetch('https://untoldapi.nl/login',  {
                method: 'POST',
            headers: {
                'Content-Type': 'application/json',            
            },
        body: JSON.stringify({ username, password }),
    })
    const data = await response.json();
    setUsername("");
    setPassword("");
    

    if (data.user){
        localStorage.setItem('token', data.user)
        window.location.href = "/Login"
    } else {
        localStorage.removeItem('token')
        alert("Incorrect")
    }
}

return (
<div className = "home-container">
<h1>Login</h1>
<input 
    value = {username}
    onChange = {(e) => setUsername(e.target.value)}
    type = "text"
    placeholder = "Username"
/>
<br/>
<br/>
<input 
    value = {password}
    onChange = {(e) => setPassword(e.target.value)}
    type = "password"
    placeholder = "Password"
/>
<br/>
<br/>
<button onClick={clientlogininfo}>Login</button>
</div>
)}

export default Home
