//import React, { useState, useEffect, useContext } from "react";
import '../CSS/DesignSeven/DesignSevenPageThree.css';
//import { DesignSevenMainCategories, DesignSevenFavoriteInputsArray } from "./DesignSevenValues.jsx";
//import { APIdatacall } from "../AppValues.jsx";

import React, { useState, useEffect, useContext, useRef } from "react";
import '../CSS/DesignSeven/DesignSevenPageFour.css';
import { designSevenMainCategories, designSevenFavoriteInputsArray, designSevenWishlistItemsArray } from "./DesignSevenValues.jsx";
import { APIdatacall } from "../AppValues.jsx";
import NotFoundImage from "../images/NotFoundImage.png"
import ImageDirections from "../images/ImageDirections.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHeartCirclePlus, faHeartCircleCheck } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Confetti from 'react-confetti'
import LinkCount from "../Home/LinkCount";

function DesignSevenPageThree() {
    
    const { designSevenFavoriteInputs, setDesignSevenFavoriteInputs } = useContext(designSevenFavoriteInputsArray);
    // eslint-disable-next-line
    const { APIdata, setAPIdata } = useContext(APIdatacall);
    // eslint-disable-next-line
    const { designSevenCategories, setDesignSevenCategories } = useContext(designSevenMainCategories);

    const [updatefavoinputshappened, setUpdatefavoinputshappened] = useState(false);
   

    function updatefavoinputs() {
    var newdesignSevenFavoriteInputslist = [];
    for (let i = 0; i < designSevenFavoriteInputs.length; i++){
        if (designSevenCategories.includes(JSON.parse(designSevenFavoriteInputs[i].favoriteindexnumber))){
        newdesignSevenFavoriteInputslist.push(designSevenFavoriteInputs[i])
    }}
    setDesignSevenFavoriteInputs(newdesignSevenFavoriteInputslist);

    }

    if (updatefavoinputshappened === false){
        updatefavoinputs();
        setUpdatefavoinputshappened(true);
    }


    const [animationhappened, setAnimationhappened] = useState(false);
    const [animationthirdpage, setAnimationthirdpage] = useState({left : {animation: 'x 0.3s', animationName: 'fadeInContent'}, right : {animation: 'x 0.4s', animationName: 'fadeInContent'}});

    function animationchange () {
        if (animationhappened === false) {
            setAnimationhappened(true)
            setAnimationthirdpage({left : {animation: '', animationName: ''}, right : {animation: '', animationName: ''}})
        }
    }

    var cityexplorertext;
    if (APIdata.styles.generalpage.cityexplorertext !== null){
        cityexplorertext = APIdata.styles.generalpage.cityexplorertext;
    } else {
        cityexplorertext = "Stadsontdekker " + APIdata.cityname;
    }

    var threepageheaderleft;
    if (APIdata.styles.pagethree.headerleft !== null){
        threepageheaderleft = APIdata.styles.pagethree.headerleft;
    } else {
        threepageheaderleft = "Voer je favoriete plekken uit je woonplaats in";
    }

    var threepagesubheaderleft;
    if (APIdata.styles.pagethree.subheaderleft !== null){
        threepagesubheaderleft = APIdata.styles.pagethree.subheaderleft;
    } else {
        threepagesubheaderleft = "Je favoriete plekken om te bezoeken helpen ons jou te inspireren " + APIdata.cityname + " te ontdekken. Jouw favoriete plekken kunnen van alles zijn, van een restaurant uit je woonplaats tot een museum van een laatste stedentrip of een wandelpark van op vakantie. Hoe meer we van jou leren kennen, hoe beter de Stadsontdekker werkt!";
    }
    var ReactRotatingText = require('react-rotating-text');


    const [fontheightheader, setFontheightheader] = useState("");
    const [fontheightsubheader, setFontheightsubheader] = useState("");
    const [lineheightsubheader, setLineheightsubheader] = useState("");
    const [stadsontdekkersize, setStadsontdekkersize] = useState("");
    const [stadsontdekkermapsuntoldfontsize, setStadsontdekkermapsuntoldfontsize] = useState("");
    const [stadsontdekkermapsuntoldmargintop, setStadsontdekkermapsuntoldmargintop] = useState("");
    const [stadsontdekkertextmargintop, setStadsontdekkertextmargintop] = useState("");
    const [categoryheaderrightfontsize, setCategoryheaderrightfontsize] = useState("");
    const [categorysubheaderrightfontsize, setCategorysubheaderrightfontsize] = useState("");
    const [inputfontsize, setInputfontsize] = useState("");
    const [autocompletefontsize, setAutocompletefontsize] = useState("");
    const [favoritefontsize, setFavoritefontsize] = useState("");

//WIDTH FIX START
    const [widthhappened, setWidthhappened] = useState(false)
    function getWindowDimensions() {
    const { innerWidth: width} = window;
    return {
      width,
    };
  }
  function handleResize() {
    var newwidth = (getWindowDimensions());
    if (newwidth.width >= 1024){
        setFontheightheader(APIdata.styles.pagethree.headerleftfontsizenormal);
        setFontheightsubheader(APIdata.styles.pagethree.subheaderleftfontsizenormal);
        setLineheightsubheader(APIdata.styles.pagethree.subheaderleftlineheightnormal);
        setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizenormal);
        setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizenormal);
        setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopnormal);
        setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintopnormal);
        setCategoryheaderrightfontsize(APIdata.styles.pagethree.categoryheaderrightfontsizenormal);
        setCategorysubheaderrightfontsize(APIdata.styles.pagethree.categorysubheaderrightfontsizenormal);
        setInputfontsize(APIdata.styles.pagethree.inputfontsizenormal);
        setAutocompletefontsize(APIdata.styles.pagethree.autocompletefontsizenormal);
        setFavoritefontsize(APIdata.styles.pagethree.favoritefontsizenormal);
  } else if (newwidth.width >=720 && newwidth.width < 1024){
        setFontheightheader(APIdata.styles.pagethree.headerleftfontsizetablet);
        setFontheightsubheader(APIdata.styles.pagethree.subheaderleftfontsizetablet);
        setLineheightsubheader(APIdata.styles.pagethree.subheaderleftlineheighttablet);
        setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizetablet);
        setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizetablet);
        setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintoptablet);
        setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintoptablet);
        setCategoryheaderrightfontsize(APIdata.styles.pagethree.categoryheaderrightfontsizetablet);
        setCategorysubheaderrightfontsize(APIdata.styles.pagethree.categorysubheaderrightfontsizetablet);
        setInputfontsize(APIdata.styles.pagethree.inputfontsizetablet);
        setAutocompletefontsize(APIdata.styles.pagethree.autocompletefontsizetablet);
        setFavoritefontsize(APIdata.styles.pagethree.favoritefontsizetablet);
  } else if (newwidth.width < 720){
        setFontheightheader(APIdata.styles.pagethree.headerleftfontsizemobile);
        setFontheightsubheader(APIdata.styles.pagethree.subheaderleftfontsizemobile);
        setLineheightsubheader(APIdata.styles.pagethree.subheaderleftlineheightmobile);
        setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizemobile);
        setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizemobile);
        setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopmobile);
        setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintopmobile);
        setCategoryheaderrightfontsize(APIdata.styles.pagethree.categoryheaderrightfontsizemobile);
        setCategorysubheaderrightfontsize(APIdata.styles.pagethree.categorysubheaderrightfontsizemobile);
        setInputfontsize(APIdata.styles.pagethree.inputfontsizemobile);
        setAutocompletefontsize(APIdata.styles.pagethree.autocompletefontsizemobile);
        setFavoritefontsize(APIdata.styles.pagethree.favoritefontsizemobile);
  }}
  if ( widthhappened === false ) {
    handleResize()
    setWidthhappened(true)
  }
useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
});
//WIDTH FIX END

    const [animationfourthpage, setAnimationfourthpage] = useState({left : {animation: 'x 0.3s', animationName: 'fadeInContent'}, right : {animation: 'x 0.4s', animationName: 'fadeInContent'}});

    var fourpagetoplocationtext;
    if (APIdata.styles.pagefour.top.toptext !== null){
        fourpagetoplocationtext = APIdata.styles.pagefour.top.toptext;
    } else {
        fourpagetoplocationtext = "Deal";
    }

    var toppositionmargintopregularcalculation;
    if (APIdata.styles.pagefour.top.toppositionmargintopregular !== null){
        toppositionmargintopregularcalculation = (-1 * APIdata.styles.pagefour.top.toppositionmargintopregular) -30;
    } else {
        toppositionmargintopregularcalculation = null;
    }

    var toppositionmargintopframecalculation;
    if (APIdata.styles.pagefour.top.toppositionmargintopregular !== null){
        toppositionmargintopframecalculation = (-1 * APIdata.styles.pagefour.top.toppositionmargintopframe) -30;
    } else {
        toppositionmargintopframecalculation = null;
    }

     // Change 2023Q1 sets a variable text for parent header 
     var fourpageparent;
     if (APIdata.styles.pagefour.subheaderleft !== null){
         fourpageparent =APIdata.styles.pagefour.categories;
     } else {
         fourpageparent =APIdata.styles.pagefour.categories;
 
     }
 
      // Change 2023Q2 sets a variable text for website
      var input_text;
      if (APIdata.styles.pagefour.input_text ===  null){
        input_text ="";
      } else {
        input_text =APIdata.styles.pagethree.input_text;
  
      }

    // Change 2023Q4 sets a variable text for website
    var typewebsites;
    if (APIdata.styles.pagefour.subheaderleft !== null){
        typewebsites =APIdata.styles.pagefour.typeofwebsite;
    } else {
        typewebsites =APIdata.styles.pagefour.typeofwebsite;
    }
      
      const [rightcategoryheaderfontsize, setRightcategoryheaderfontsize] = useState("");
      const [rightcategorysubheaderfontsize, setRightcategorysubheaderfontsize] = useState("");

      const [subcategories, setSubcategories] = useState([]);
      const [recos, setRecos] = useState([]);
      const [displayCategoryText, setDisplayCategoryText] = useState(false);
      const [rightsubcategoryfontsize, setRightsubcategoryfontsize] = useState("");

 

        useEffect(() => {
          getRecosAPI();
        }, [designSevenFavoriteInputs.length]);
        
        async function getRecosAPI() {
          var stringforapicall = '';
        
          //for (let i = 0; i < DesignSevenFavoriteInputs.length; i++) {
            //stringforapicall = stringforapicall + '&favoritesid=' + DesignSevenFavoriteInputs[i].favoriteid;
          //}
          //for (let i = 0; i < designSevenFavoriteInputs.length; i++) {
            //const favoriteid = designSevenFavoriteInputs[i].favoriteid.slice(0, -1); // Delete the last character
        
            //for (let j = 0; j < designSevenCategories.length; j++) {
              //stringforapicall = stringforapicall + '&favoritesid=' + favoriteid + designSevenCategories[j];
            //}
          //}
          
          if (designSevenFavoriteInputs.length === 100) {
            for (let i = 0; i < designSevenCategories.length; i++) {
              stringforapicall = stringforapicall + '&favoritesid=643fc15666f6433e20bd17c0' + designSevenCategories[i];
            }
          } else {
            for (let i = 0; i < designSevenFavoriteInputs.length; i++) {
              const favoriteid = designSevenFavoriteInputs[i].favoriteid.slice(0, -1); // Delete the last character
        
              for (let j = 0; j < designSevenCategories.length; j++) {
                stringforapicall = stringforapicall + '&favoritesid=' + favoriteid + designSevenCategories[j];
              }
            }}

          const urlreconojson = await fetch('https://untoldapi.nl/recommendations?favoritesid=arrayitem' + stringforapicall + '&city=' + APIdata.city + '&client=' + APIdata.clientname);
          var urlreco = await urlreconojson.json();

          recosToList(urlreco);
          setRecos(urlreco);
          setDisplayCategoryText(true);
        }
        
        function recosToList(urlreco) {
          var listsubcategories = [];
          for (let i = 0; i < urlreco.length; i++) {
            var subcategories = [];
            for (let j = 0; j < urlreco[i].recos.length; j++) {
              subcategories.push(urlreco[i].recos[j].subcategory);
            }
        
            listsubcategories.push({ indexvalue: urlreco[i].indexvalue, subcategories: subcategories, status: 0 });
          }
          setSubcategories(listsubcategories);
        }
        

        function clickedonsubcategory (event){
        
        var indexvalue = event.target.id.split(' ')[0]
        var subcategory = event.target.id.split(' ')[1]
        var listtobeexported = [];
        
        for (let i = 0; i < subcategories.length; i++){
        
        if (subcategories[i].indexvalue === indexvalue){
            listtobeexported.push({indexvalue: indexvalue, subcategories: subcategories[i].subcategories, status: parseInt(subcategory)})
        } else (
            listtobeexported.push(subcategories[i])
        )}
        setSubcategories(listtobeexported)
        }
        
        function scrollfunction (event){
        var indexvalue = event.target.id.split(' ')[0];
        var direction = event.target.id.split(' ')[1];
        var index = event.target.id.split(' ')[2];
        var id = indexvalue + " indexvaluerecos " + index; 
        setTimeout(() => {
        
        const outsider = document.getElementById(id);
        const distance = 630;
        function scrollLeft() {
            outsider.scrollBy({
              left: -distance,
              behavior: 'smooth'
            });
          }
          function scrollRight() {
            outsider.scrollBy({
              left: distance,
              behavior: 'smooth'
            });
          }
        
          if (direction === "indexvalueback"){
            scrollLeft ();
          } else {
            scrollRight ();
          }
        }, 0);
        }
        
        function MouseOver(event) {
            var indexvalue = event.target.id.split(' ')[0];
            var direction = event.target.id.split(' ')[1];
            var index = event.target.id.split(' ')[2];
            var id = indexvalue + " " + direction + " " + index + " arrow"; 
            document.getElementById(id).style.borderColor= "white"; 
            document.getElementById(indexvalue + " " + direction + " " + index).style.backgroundColor= APIdata.styles.generalpage.colorone; 
          }
          function MouseOut(event){
            var indexvalue = event.target.id.split(' ')[0];
            var direction = event.target.id.split(' ')[1];
            var index = event.target.id.split(' ')[2];
            var id = indexvalue + " " + direction + " " + index + " arrow"; 
            document.getElementById(id).style.borderColor = APIdata.styles.generalpage.colorone; 
            document.getElementById(indexvalue + " " + direction + " " + index).style.backgroundColor= "transparent"; 
          }
        
          const [positionid, setPositionid] = useState();
          const [positionscroll, setPositionscroll] = useState(0);
          const [positionhappened, setPositionhappened] = useState(false);
      
          function RecommendationsDisplay (){
          
            function scrollcomp() {
                const parentcategoryboxxx = document.getElementById(positionid);
            
                setTimeout(() => {
                    parentcategoryboxxx.scrollBy({
                    left: -positionscroll,
                  });
                },0)
            
                setTimeout(() => {
                    parentcategoryboxxx.scrollBy({
                    left: positionscroll,
                  });
                },0)
            }
            
                useEffect(() => {
                   scrollcomp();
                }, [])
            
                const [designSevenWishlistItemstwohappened, setDesignSevenWishlistItemstwohappened] = useState(false);
                var designSevenWishlistItemstwolijst = [""];
            
                const { designSevenWishlistItems, setDesignSevenWishlistItems } = useContext(designSevenWishlistItemsArray);
                
                if (designSevenWishlistItemstwohappened === false && designSevenWishlistItems.length > 0){
                    for ( let i = 0; i < designSevenWishlistItems.length; i++ ){
                    designSevenWishlistItemstwolijst.push(designSevenWishlistItems[i].parentcategory + designSevenWishlistItems[i].title + designSevenWishlistItems[i].address);
                }
                setDesignSevenWishlistItemstwohappened(true);
                }
            
                const [designSevenWishlistItemstwo, setDesignSevenWishlistItemstwo] = useState(designSevenWishlistItemstwolijst);
              
                function addtowishlist (event){
               
                    var nojsonitem = event.target.id.split(' category=')[0];
                    var jsonitem = JSON.parse(nojsonitem)
                    var parentcategory = event.target.id.split(' category=')[1];
                    var itemtopush = { reco_id: jsonitem.reco_id, parentcategory: parentcategory, title: jsonitem.title, website: jsonitem.website, imageurl: jsonitem.imageurl, category: jsonitem.category, address: jsonitem.address, score: jsonitem.score, inlijsting: jsonitem.inlijsting, toplocation: jsonitem.toplocation }
                    var inbetweenlist =  designSevenWishlistItems;
                    var inbetweenlisttwo = [];
            
                    if (inbetweenlist.length > 0){
                        var inthelist = false;
                        var outofthelist;
                    for ( let i = 0; i < inbetweenlist.length; i++ ){
                        if (JSON.stringify(itemtopush) === JSON.stringify(inbetweenlist[i]) ){
                            inthelist = true
                            outofthelist = i
                        }}
                        if (inthelist === false){
                            inbetweenlist.push( itemtopush );
                        } else if (inthelist === true){
                            inbetweenlist.splice(outofthelist, 1);
                        }} else {
                        inbetweenlist.push( itemtopush );
                    }
            
                    for ( let i = 0; i < inbetweenlist.length; i++ ){
                        inbetweenlisttwo.push(inbetweenlist[i].parentcategory + inbetweenlist[i].title + inbetweenlist[i].address);
                    }
                    setDesignSevenWishlistItems(inbetweenlist);
                    setDesignSevenWishlistItemstwo(inbetweenlisttwo);

                    var parentcategorybox = event.target.id.split(' category=')[2];
            
                    const parentcategoryboxx = document.getElementById(parentcategorybox);
                    var distancescrolled = parentcategoryboxx.scrollLeft;
            
                    if (positionhappened === false){
                    setPositionid(parentcategorybox);
                    setPositionscroll(distancescrolled);
                    setPositionhappened(true);
                    ;
                }
             
                }
                async function test (event){
                    // eslint-disable-next-line
                  }
            
                return(
            
                <div className="design-four-page-four-right-recommendations-container" 
                    style={{
                        animation: animationfourthpage.right.animation,
                        animationName: animationfourthpage.right.animationName,
                        fontSize:12,
                        paddingBottom: 150,
                }}>

                {recos.length === 10000? 
                    <div className="design-four-page-four-lds-dual-ring"></div>
                     : 
                null}
            
            {subcategories.map((item, index)=>(
                    <div key={index}>
            
            {displayCategoryText &&
              <div className="design-four-page-four-subcategories-recos-parentcategory"
              style={{fontFamily: APIdata.styles.pagefour.rightcategoryheaderfont, fontSize: rightcategoryheaderfontsize}}>
              {fourpageparent[item.indexvalue]}
              </div>
            }
            

            <div className="design-four-page-four-subcategories-recos-category-description"
            style={{fontFamily: APIdata.styles.pagefour.rightcategorysubheaderfont, fontSize: rightcategorysubheaderfontsize}}>
            { APIdata.categories[item.indexvalue].categoryrecodescription}
            </div>
        
            {item.subcategories.map((itemtwo, indexx)=>(
            <div className="design-four-page-four-recos-container" key={indexx + 936298758} id={item.indexvalue + " indexvaluerecos " + indexx}>
            
            {item.status === indexx ? 
            <div>
                {recos[index].recos[item.status].recos.map((itemthree, indexxx)=>(
                    <div key = {(12345+indexxx)} style={{backgroundColor: "#f7f7f7", display: "inline-block", verticalAlign: "top"}}>
                    {itemthree.inlijsting === 0? 
                      <div className="design-four-page-four-reco-card">
                      <div className="design-four-page-four-reco-card-image-outer-div" style={{ backgroundImage: `url(${NotFoundImage})` , backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                      <div className="design-four-page-four-reco-card-image-div" style={{ backgroundImage: `url(${itemthree.imageurl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                    </div>
                    </div>
             
                    
                    {itemthree.toplocation !== false? 
                    // eslint-disable-next-line
                                <div className="design-four-page-four-reco-card-top" style={{ marginLeft: APIdata.styles.pagefour.top.toppositionmarginleftregular, marginTop: APIdata.styles.pagefour.top.toppositionmargintopregular, marginBottom: toppositionmargintopregularcalculation, backgroundColor: APIdata.styles.pagefour.top.topbackgroundcolor, backgroundImage: `url("` + APIdata.styles.pagefour.top.topbackgroundimage + `")` , backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}}>
                                <div className="design-four-page-four-reco-card-top-text">
                                {itemthree.toplocation}
                                </div>
                                </div>
                    : null}
                    <div className="design-four-page-four-reco-card-title">
                    {/* {itemthree.title} */}
                    <LinkCount origin={typewebsites[item.indexvalue]+ "," + itemthree.category} href={"http://" + itemthree.website} target="_blank" rel="noreferrer" style={{textDecoration: "inherit", color: "inherit", cursor: "pointer"}}>
                    {itemthree.title}
                    </LinkCount>
                    </div>
                    <div className="design-four-page-four-reco-card-match" style={{color: APIdata.styles.generalpage.colorone}}>
                    {itemthree.score}% Match 
            
                    </div>
                    <div className="design-four-page-four-reco-card-category">
                    {itemthree.category}
            
                    </div>
                    <div className="design-four-page-four-reco-card-website" onClick={(event) => { test();}} style={{color: APIdata.styles.generalpage.colorone}}>
                    <LinkCount origin={typewebsites[item.indexvalue]+ "," + itemthree.category} href={"http://" + itemthree.website} target="_blank" rel="noreferrer" style={{textDecoration: "inherit", color: "inherit", cursor: "pointer"}}>
                    <button style={{border: "none", padding: "8px 18px", textDecoration: "none", marginTop: "-10px", color: "white", backgroundColor: APIdata.styles.generalpage.colorone}}>{typewebsites[item.indexvalue]}</button>
                    </LinkCount>
                    </div>
                    
                   
                    <div className="design-four-page-four-reco-card-address" style={{backgroundColor: APIdata.styles.generalpage.colorone}}>
                    <LinkCount origin="Address" href={"https://maps.google.com/?q="+itemthree.address} target="_blank" rel="noreferrer">
                    <div className="design-four-page-four-reco-card-address-image" style={{ backgroundImage: `url(${ImageDirections})` , backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", marginTop: "8px"}}>
                    </div>
                    </LinkCount>
                    </div>
                    
            </div>
                :
                <div className="design-four-page-four-reco-card-list" style={{backgroundColor: APIdata.styles.pagefour.frames[itemthree.inlijsting-1].backgroundcolor, backgroundImage: `url(${APIdata.styles.pagefour.frames[itemthree.inlijsting-1].image})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                <div className="design-four-page-four-reco-card-image-outer-div-list" style={{ backgroundImage: `url(${NotFoundImage})` , backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                <div className="design-four-page-four-reco-card-image-div-list" style={{ backgroundImage: `url(${itemthree.imageurl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                </div>
                </div>
                <div className="design-four-page-four-reco-card-score-list" style={{backgroundColor: APIdata.styles.generalpage.colorone}}>
                {itemthree.toplocation}
                </div>
                {itemthree.toplocation !== false? 
                // eslint-disable-next-line
                          null
                : null}
                <div className="design-four-page-four-reco-card-content-list">
                <div className="design-four-page-four-reco-card-title-list">
                {/* {itemthree.title} */}
                <LinkCount origin="Website" href={"https://" + itemthree.website} target="_blank" rel="noreferrer">
                <div className="design-four-page-four-reco-card-website-list" style={{marginTop: "0px", marginLeft: "0px", fontSize: "14px", textDecoration: "inherit", color: "inherit", cursor: "pointer", fontWeight: "600"}} >
                {itemthree.title}
                </div>
                </LinkCount>
                </div>
                <div className="design-four-page-four-reco-card-match" style={{color: APIdata.styles.generalpage.colorone}}>
                {itemthree.score}% Match 
        
                </div>
                <div className="design-four-page-four-reco-card-category-list">
                {itemthree.category}
                </div>
                <LinkCount origin="Website" href={"https://" + itemthree.website} target="_blank" rel="noreferrer">
                <div className="design-four-page-four-reco-card-website-list" style={{color: APIdata.styles.generalpage.colorone} } >
                <button style={{border: "none", padding: "8px 18px", textDecoration: "none", color: "white", backgroundColor: APIdata.styles.generalpage.colorone}}>{typewebsites[item.indexvalue]}</button>
                </div>
                </LinkCount>
                
               
        
                <LinkCount origin="Address" href={"https://maps.google.com/?q="+itemthree.address} target="_blank" rel="noreferrer">
                <div className="design-four-page-four-reco-card-address-list" style={{backgroundColor: APIdata.styles.generalpage.colorone}}>
                <div className="design-four-page-four-reco-card-address-image-list" style={{ backgroundImage: `url(${ImageDirections})` , backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", marginTop: "30px"}}>
                </div>
                </div>
                </LinkCount>
                </div>
            </div>}
            </div>
             ))}
                </div>
                :
            null
            }
                </div>
                    ))}    
                    </div>
                    ))}  
                    </div>
                )}
          
    //RIGHT SIDE

    var threepageautocompletetrigger;
    if (APIdata.styles.pagethree.autocompletetrigger !== null){
        threepageautocompletetrigger = APIdata.styles.pagethree.autocompletetrigger;
    } else {
        threepageautocompletetrigger = 4;
    }

    var autocompletenumberofsuggestions;
    if ( APIdata.styles.pagethree.autocompletenumberofsuggestions !== null){
        autocompletenumberofsuggestions =  APIdata.styles.pagethree.autocompletenumberofsuggestions;
    } else {
        autocompletenumberofsuggestions = 5;
    }

function onClickFavorite (event) {
    var favoritelist = [];
    var favoriteobject;
    var favoriteid = event.target.id;
    var favoritetitle = document.getElementById(favoriteid).getAttribute("data-title");
    var favoriteindexnumber = document.getElementById(favoriteid).getAttribute("data-indexnumber");

    for (let i = 0; i < designSevenFavoriteInputs.length; i++){
    favoritelist.push(designSevenFavoriteInputs[i]);
       
    }

    favoriteobject = {
        favoriteid: favoriteid,
        favoritetitle: favoritetitle,
        favoriteindexnumber: favoriteindexnumber,
        favoritehtml: <div className="design-four-page-three-right-favorite-text" style={{fontFamily: APIdata.styles.pagethree.favoritefont, fontSize: favoritefontsize}}>{favoritetitle}</div>};

        favoritelist.push(favoriteobject);

    favoritelist = favoritelist.filter((value, index, self) =>
    index === self.findIndex((t) => (
    t.favoriteid === value.favoriteid && t.favoriteindexnumber === value.favoriteindexnumber
  )))
    setDesignSevenFavoriteInputs(favoritelist)
}


function onClickKruis (event) {
    var favoriteidkruis = event.target.id;
    var favoriteidwithoutkruis = favoriteidkruis.substring(0, favoriteidkruis.length - 5);
    var filtered = designSevenFavoriteInputs.filter(function( obj ) {
        return obj.favoriteid !== favoriteidwithoutkruis;
    });
    setDesignSevenFavoriteInputs(filtered)
    setRecos([...recos]);
    const recos_empty = recos
    recos_empty[0].recos[0].recos = []
    setRecos(recos_empty)
    setDisplayCategoryText(false);
    // console.log(recos[0].recos[0].recos)
    //onkruis
}

function Inputdisplay () {

  designSevenCategories.sort(function(a, b){return a-b});

    var startquery = []
    for (let i = 0; i < designSevenCategories.length; i++){
        startquery.push({indexnumber: designSevenCategories[i], indexvalue: ""})
    }

    var startautocomplete = []

    for (let i = 0; i < APIdata.categories.length; i++){
        startautocomplete.push({indexnumber: i, indexvalue: ""})
    }

    const [query, setQuery] = useState(startquery);
    const [autocompletetrigger, setAutocompletetrigger] = useState(false);
    const [autocomplete, setAutocomplete] = useState(startautocomplete);
    const [finalautocomplete, setFinalautocomplete] = useState(startautocomplete);
    const [previousinputbarfocus, setPreviousinputbarfocus] = useState();

    function handleInputFocus (index){
    var inputbarfocus = index.target.name;
    if (previousinputbarfocus !== inputbarfocus){
        setPreviousinputbarfocus(inputbarfocus);
        setAutocomplete(startautocomplete);
        setFinalautocomplete(startautocomplete);
        setQuery(startquery);
        Array.from(document.querySelectorAll("input")).forEach(
              input => (input.value = "")
            );
    }
}

    function updateautocomplete (list) {
        var newlist = [];
        
        for (let i = 0; i < list.length; i++){
            var newlistindexvaluelist = [];
            var newlistindexnumber = list[i].indexnumber
            var newlistindexvalue =  list[newlistindexnumber].indexvalue;
            var forlooplength;

            if (newlistindexvalue.length > autocompletenumberofsuggestions){
                forlooplength = autocompletenumberofsuggestions;
            } else {
                forlooplength = newlistindexvalue.length;
            }

            for (let j = 0; j < forlooplength; j++){
                newlistindexvaluelist.push(<div
                key = {j}
                className="autocompletesuggestions"
                onClick={onClickFavorite}
                id={newlistindexvalue[j]._id + newlistindexnumber}
                data-title={newlistindexvalue[j].title}
                data-indexnumber={newlistindexnumber}
                style={{
                    fontFamily: APIdata.styles.pagethree.autocompletefont,
                    fontSize: autocompletefontsize}}>{newlistindexvalue[j].title + " (" + newlistindexvalue[j].category + " in " + newlistindexvalue[j].city + ")"}</div>)
        }

        newlist.push({indexnumber: newlistindexnumber, indexvalue: newlistindexvaluelist})
    }
        setFinalautocomplete(newlist);
    }

    function inputHandler (event) {
        
        setAutocompletetrigger(true);
        var indexnumber = event.target.name;
        var indexvalue = event.target.value;
        for (let i = 0; i < query.length; i++){
            // eslint-disable-next-line
           if (query[i].indexnumber == indexnumber){
                var indexlijst = [];

            for (let j = 0; j < query.length; j++){
                    // eslint-disable-next-line
                if (query[j].indexnumber == indexnumber){
                    indexlijst.push({indexnumber: indexnumber, indexvalue: indexvalue})
                } else {
                    indexlijst.push({indexnumber: query[j].indexnumber, indexvalue: query[j].indexvalue})
                }
                setQuery(indexlijst);
            }} else {}}
        };

        async function getAutocomplete (){
        for (let i = 0; i < designSevenCategories.length; i++){
            var numbertobeinserted = designSevenCategories[i];

            if (query[i].indexvalue.length !== undefined){
                if (query[i].indexvalue.length < threepageautocompletetrigger){
                    if (autocompletetrigger === true) {
                        setAutocompletetrigger(false);
                    }
                } else if (query[i].indexvalue.length >= threepageautocompletetrigger){
                    if (autocompletetrigger === true) {

           setAutocompletetrigger(false);
            const apicallautocomplete = query[i].indexvalue.replaceAll(" ", "%20");
            // const urlautocompletenojson = await fetch('https://untoldapi.nl/autocomplete?term=' + apicallautocomplete);
            //const urlautocompletenojson = await fetch('https://untoldapi.nl/autocomplete?term=' + apicallautocomplete + "&searchscope=" + APIdata.searchscope);
            const urlautocompletenojson = await fetch('https://untoldapi.nl/autocomplete?term=' + apicallautocomplete + "&searchscope=" + APIdata.searchscope);
            var urlautocomplete = await urlautocompletenojson.json();
            var listautocomplete = [];
            for (let j = 0; j < autocomplete.length; j++){
                // eslint-disable-next-line
                if (autocomplete[j].indexnumber == numbertobeinserted){
                    listautocomplete.push({indexnumber: numbertobeinserted, indexvalue: urlautocomplete});
                } else {
                    listautocomplete.push({indexnumber: autocomplete[j].indexnumber, indexvalue:autocomplete[j].indexvalue});
                }
            }
            
            updateautocomplete(listautocomplete);

            }}}
        }
    }
        getAutocomplete();

        function handleClickForFocus(event) {
            var id = event.target.id
            setTimeout(() => {
                window.document.getElementById(id).focus();
                          }, 0);
        };
       
        let isCategoryRendered = false;


        return (
            <div className="design-four-page-three-right-categories-container"
            style={{
                    animation: animationthirdpage.right.animation,
                    animationName: animationthirdpage.right.animationName,
        }}
        >


        {APIdata.categories.map((category, index) => {


  if (!isCategoryRendered && designSevenCategories.includes(index)) {
    isCategoryRendered = true;

  return (
    <div
      className="design-four-page-three-right-category-container"
      key={index}
      id={index}
      style={{
        // Add your styles here
      }}
      value={index}
    >
      {designSevenCategories.includes(index) ? (
        <div
          className="design-four-page-three-right-category-part"
          style={{
            //backgroundColor:  APIdata.styles.generalpage.colorone
          }}
        >
          <div
            className="design-four-page-three-right-category-part-header"
            style={{
              fontFamily: APIdata.styles.pagethree.rightheaderfont,
              fontSize: categoryheaderrightfontsize,
              // Add your styles here
            }}
          >
            {category.texts.categoryrecodescription}
          </div>
          <div>
              {designSevenFavoriteInputs.map((favorite, indexx) => (
                <div
                  className="design-four-page-three-right-favorites-container"
                  key={indexx}
                  id={indexx}
                  style={{
                    // Add your styles here
                  }}
                  value={indexx}
                >
                  {designSevenCategories.includes(
                    parseInt(favorite.favoriteindexnumber)
                  ) //&& index === parseInt(favorite.favoriteindexnumber)
                   ? (
                    <div
                      className="design-four-page-three-right-favorite-part"
                      style={{
                        backgroundColor: APIdata.styles.generalpage.colortwo,
                      }}
                    >
                      {favorite.favoritehtml}
                      <div className="design-four-page-three-right-favorite-kruis">
                        <img
                          src="DesignThree/CategoryImages/Cross Icon White.png"
                          alt="img"
                          className="design-four-page-three-right-favorite-kruis-image"
                          id={favorite.favoriteid + "kruis"}
                          onClick={onClickKruis}
                          kruis-title={favorite.favoritetitle}
                          kruis-indexnumber={
                            favorite.favoriteindexnumber
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>

          <div
            className="design-one-page-three-right-category-part-subheader"
            style={{
              fontFamily: APIdata.styles.pagethree.rightheaderfont,
              fontSize: categorysubheaderrightfontsize,
              color: APIdata.styles.generalpage.colorone,
              // Add your styles here
            }}
          >
            {/* {category.texts.categorydescription} */}

          </div>
          <div>
            <input
              value={query.index}
              onChange={inputHandler}
              placeholder={category.texts.categoryplaceholder}
              name={index}
              className="design-four-page-three-right-category-part-inputbar"
              onFocus={handleInputFocus}
              id={"id" + index}
              onClick={handleClickForFocus}
              style={{
                fontFamily: APIdata.styles.pagethree.inputfont,
                fontSize: inputfontsize,
              }}
              autoComplete="off"
              autoCorrect="off"
            />
            <div className="design-four-page-three-right-category-part-suggestions-container">
              {finalautocomplete.map((autocompleteitem, indexx) => (
                <div
                  key={indexx}
                  id={indexx}
                  style={{
                    // Add your styles here
                  }}
                  value={indexx}
                >
                  {designSevenCategories.includes(autocompleteitem.indexnumber) &&
                  index === autocompleteitem.indexnumber ? (
                    <div className="design-four-page-three-right-category-part-suggestion">
                      {autocompleteitem.indexvalue}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>

          </div>
        </div>
      ) : null}
    </div>
  );}
      return null;
    })}

 <div
     style={{
        fontFamily: APIdata.styles.pagethree.inputfont,
        fontSize: inputfontsize,
        }} >📍<ReactRotatingText cursor={false} pause= {750} deletingInterval={0} emptyPause={200} items={['Loetje in Den Haag', 'Wandelpark in Valkenswaard', 'Rijksmuseum in Amsterdam', 'Amaze VR in Den Haag',  "Prinsentuin in Groningen"]} /></div>
    </div>


)};

    return (
        <div className="design-four-page-three-body" onClick={animationchange}>
        <div className="design-four-page-three-left-body"
        style={{
            backgroundColor: APIdata.styles.generalpage.leftcolor,
            paddingBottom: fontheightsubheader
        }}>

        <div className="design-four-page-three-left-cityexplorer"
        style={{
            marginTop: APIdata.styles.generalpage.bartopheight,
            paddingTop: APIdata.styles.generalpage.citydiscoveryheight
        }}>
        <div className="design-four-page-three-left-cityexplorer-logo">
        <img src={require("../images/maps-untold-logo-small.png")} alt="logo" className="design-four-page-three-left-cityexplorer-logo-image"/>
        </div>
        <div className="design-four-page-three-left-cityexplorer-mapsuntold">
        <div className="design-four-page-three-left-cityexplorer-mapsuntold-text"
        style={{
            fontFamily: APIdata.styles.generalpage.stadsontdekkermapsuntoldfont,
            fontSize: stadsontdekkermapsuntoldfontsize,
            marginTop: stadsontdekkermapsuntoldmargintop
        }}>
        Maps Untold
        </div>
        </div>
        <div className="design-four-page-three-left-cityexplorer-line">
        </div>
      
        <div className="design-four-page-three-left-cityexplorer-city">
        <div className="design-four-page-three-left-cityexplorer-city-text"
        style={{
            fontFamily: APIdata.styles.generalpage.stadsontdekkertextfont,
            color: APIdata.styles.generalpage.colorone,
            fontSize: stadsontdekkersize,
            marginTop: stadsontdekkertextmargintop
        }}>
        {cityexplorertext}
        </div>
        </div>
        </div>

        <div className="design-four-page-three-left-header"
        style={{
            fontFamily:  APIdata.styles.generalpage.headerleftfont,
            fontSize: fontheightheader,
            color: APIdata.styles.generalpage.headerleftcolor,
            animation: animationthirdpage.left.animation,
            animationName: animationthirdpage.left.animationName,
        }}>
        {threepageheaderleft}
        </div>
        <div className="design-four-page-three-left-subheader"
        style={{
            fontFamily:  APIdata.styles.generalpage.subheaderleftfont,
            fontSize: fontheightsubheader,
            color: APIdata.styles.generalpage.subheaderleftcolor,
            lineHeight: lineheightsubheader,
            animation: animationthirdpage.left.animation,
            animationName: animationthirdpage.left.animationName,
}}>
        {threepagesubheaderleft}

        </div>
        </div>
        <div className="design-four-page-three-right-body"
        style={{
            backgroundColor: APIdata.styles.generalpage.rightcolor,
            paddingBottom: 50,
        }}>
        <div className="design-four-page-three-right-first-container" style={{
            marginTop: APIdata.styles.pagethree.rightmargintop,

    }} key={designSevenFavoriteInputs.length}>
    </div>

    <Inputdisplay/>

        <RecommendationsDisplay/>

        </div>
        <ToastContainer />
 
        </div>
    )
}

export default DesignSevenPageThree;
