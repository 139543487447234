import React, { useEffect, useState } from "react";
import jwt from "jsonwebtoken"
import LoginScreen from "./LoginScreen.jsx";
import { clientdatacontext } from "./LoginValues.jsx";


function Login () {

    const [verified, setVerified] = useState(false)
    const [clientdata, setClientdata] = useState("")


    async function populateQuote () {
        const req = await fetch ('https://untoldapi.nl/login', {
            // const req = await fetch ('https://untoldapi.nl/login', {
                // const req = await fetch ('https://untoldapi.nl/login', {

            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        })
        const data = await req.json()

        if(data.status === 'ok'){
            setVerified(true);
            setClientdata(data);
        } else if (data === null) {
            localStorage.removeItem('token')
            window.location.href = "/";
        }}

    useEffect (() => {
        const token = localStorage.getItem('token')
        if (token){
            const user = jwt.decode(token)
         if (!user) {
            localStorage.removeItem('token')
            window.location.href = "/"
        } else {
            populateQuote()
        }} else {
            localStorage.removeItem('token')
            window.location.href = "/"
        }
    }, [])

    return (
        <div>

            <clientdatacontext.Provider value = {{ clientdata, setClientdata }}>
            {verified === true ? <LoginScreen/> : <Verifying/> }
            </clientdatacontext.Provider>

        </div>
    )

}



function Verifying () {
    return (
        <div className="verifying-page">
            <div className="verifying-page-text">
                Verifying...
            </div>
        </div>
    )}

export default Login
