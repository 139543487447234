import React, { useContext }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsCategoriesAlternatives () {

// eslint-disable-next-line
    const { clientdata, setClientdata } = useContext(clientdatacontext);

    var categoriestatusstart = [];

    for (let i = 0; i < clientdata.categories.length; i++){ 
        categoriestatusstart.push({parentcategory: clientdata.categories[i].parentcategory, subcategorynumber: 0});
    }


    async function removealternative (event) {
        var categoryalternativesplit = event.target.id.split(" ");
        var parentcategoryalternative = categoryalternativesplit[0];
        var indexcategoryalternative = categoryalternativesplit[1];
        // const urlcategoriesalternativesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=alternatives&alternativecategoryaction=delete&parentcategory=' + parentcategoryalternative + '&indexofitem=' + indexcategoryalternative + '&apikey=' + clientdata.apikey)
        // const urlcategoriesalternativesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=alternatives&alternativecategoryaction=delete&parentcategory=' + parentcategoryalternative + '&indexofitem=' + indexcategoryalternative + '&apikey=' + clientdata.apikey)
        const urlcategoriesalternativesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=alternatives&alternativecategoryaction=delete&parentcategory=' + parentcategoryalternative + '&indexofitem=' + indexcategoryalternative + '&apikey=' + clientdata.apikey)
        var urlcategoriesalternativeswithoutrecos = await urlcategoriesalternativesnojson.json();
        var urlcategoriesalternatives = {clientname: urlcategoriesalternativeswithoutrecos.clientname, city: urlcategoriesalternativeswithoutrecos.city, apikey: urlcategoriesalternativeswithoutrecos.apikey, cityname: urlcategoriesalternativeswithoutrecos.cityname, excludedlocations: urlcategoriesalternativeswithoutrecos.excludedlocations, websiteChanges: urlcategoriesalternativeswithoutrecos.websiteChanges, imageChanges: urlcategoriesalternativeswithoutrecos.imageChanges, topChanges: urlcategoriesalternativeswithoutrecos.topChanges, inlijstingChanges: urlcategoriesalternativeswithoutrecos.inlijstingChanges, lengthRecos: urlcategoriesalternativeswithoutrecos.lengthRecos, categories: urlcategoriesalternativeswithoutrecos.categories, minimalrecos: urlcategoriesalternativeswithoutrecos.minimalrecos, status: urlcategoriesalternativeswithoutrecos.status, cityrecos: clientdata.cityrecos} 
        setClientdata(urlcategoriesalternatives);
    }

    async function addalternative (event) {

        var addid = event.target.id.split(" ");
        var parentcategoryalternativeadd = addid[0];

        var titleadd = document.getElementById(parentcategoryalternativeadd + " title").value;
        var websiteadd = document.getElementById(parentcategoryalternativeadd + " website").value;
        var imageurladd = document.getElementById(parentcategoryalternativeadd + " imageurl").value;
        var categoryadd = document.getElementById(parentcategoryalternativeadd + " category").value;
        var addressadd = document.getElementById(parentcategoryalternativeadd + " address").value;
        var scoreadd = document.getElementById(parentcategoryalternativeadd + " score").value;
        var inlijstingadd = document.getElementById(parentcategoryalternativeadd + " inlijsting").value;
        var toplocationadd = document.getElementById(parentcategoryalternativeadd + " toplocation").value;

        if ( isNaN(Number(parseInt(scoreadd))) === true ){
            alert("score has to be a number" )
        } else {

        if ( isNaN(Number(parseInt(inlijstingadd))) === true ){
            alert("inlijsting has to be a number" )
        } else {

        if ( toplocationadd !== 'true' && toplocationadd !== 'false' ){
            alert("toplocation has to be 'true' or 'false'" )
        } else {
        // const urlcategoriesalternativesaddnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=alternatives&alternativecategoryaction=add&parentcategory=' + parentcategoryalternativeadd + '&titleadd=' + titleadd + '&websiteadd=' + websiteadd + '&imageurladd=' + imageurladd + '&categoryadd=' + categoryadd + '&addressadd=' + addressadd + '&scoreadd=' + scoreadd + '&inlijstingadd=' + inlijstingadd + '&toplocationadd=' + toplocationadd + '&apikey=' + clientdata.apikey)
        // const urlcategoriesalternativesaddnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=alternatives&alternativecategoryaction=add&parentcategory=' + parentcategoryalternativeadd + '&titleadd=' + titleadd + '&websiteadd=' + websiteadd + '&imageurladd=' + imageurladd + '&categoryadd=' + categoryadd + '&addressadd=' + addressadd + '&scoreadd=' + scoreadd + '&inlijstingadd=' + inlijstingadd + '&toplocationadd=' + toplocationadd + '&apikey=' + clientdata.apikey)
        const urlcategoriesalternativesaddnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=alternatives&alternativecategoryaction=add&parentcategory=' + parentcategoryalternativeadd + '&titleadd=' + titleadd + '&websiteadd=' + websiteadd + '&imageurladd=' + imageurladd + '&categoryadd=' + categoryadd + '&addressadd=' + addressadd + '&scoreadd=' + scoreadd + '&inlijstingadd=' + inlijstingadd + '&toplocationadd=' + toplocationadd + '&apikey=' + clientdata.apikey)
        var urlcategoriesalternativesaddwithoutrecos = await urlcategoriesalternativesaddnojson.json();
        var urlcategoriesalternativesadd = {clientname: urlcategoriesalternativesaddwithoutrecos.clientname, city: urlcategoriesalternativesaddwithoutrecos.city, apikey: urlcategoriesalternativesaddwithoutrecos.apikey, cityname: urlcategoriesalternativesaddwithoutrecos.cityname, excludedlocations: urlcategoriesalternativesaddwithoutrecos.excludedlocations, websiteChanges: urlcategoriesalternativesaddwithoutrecos.websiteChanges, imageChanges: urlcategoriesalternativesaddwithoutrecos.imageChanges, topChanges: urlcategoriesalternativesaddwithoutrecos.topChanges, inlijstingChanges: urlcategoriesalternativesaddwithoutrecos.inlijstingChanges, lengthRecos: urlcategoriesalternativesaddwithoutrecos.lengthRecos, categories: urlcategoriesalternativesaddwithoutrecos.categories, minimalrecos: urlcategoriesalternativesaddwithoutrecos.minimalrecos, status: urlcategoriesalternativesaddwithoutrecos.status, cityrecos: clientdata.cityrecos} 
        setClientdata(urlcategoriesalternativesadd);

        document.getElementById(parentcategoryalternativeadd + " title").value = ""
        document.getElementById(parentcategoryalternativeadd + " website").value = ""
        document.getElementById(parentcategoryalternativeadd + " imageurl").value = ""
        document.getElementById(parentcategoryalternativeadd + " category").value = ""
        document.getElementById(parentcategoryalternativeadd + " address").value = ""
        document.getElementById(parentcategoryalternativeadd + " score").value = ""
        document.getElementById(parentcategoryalternativeadd + " inlijsting").value = ""
        document.getElementById(parentcategoryalternativeadd + " toplocation").value = ""
    }}}

    }

    return (
        <div className="login-recommendations-categories-categories-display-container">
    
            <div className="login-recommendations-categories-categories-overview-header">Overview of categories</div>
            { clientdata.categories.length > 0 ?
            <div>
            {clientdata.categories.map((item, index)=>(
                <div key={index} className="login-recommendations-categories-categories-parentcategory-container">
                <div key={index} className="login-recommendations-categories-categories-parentcategory">
                {item.parentcategory}
                </div>

                {clientdata.categories[index].alternatives.map((itemm, indexx)=>(
                <div key={indexx} style={{backgroundColor: "white", marginBottom: 10}}>
                title: {itemm.title}
                <br/>
                website: {itemm.website}
                <br/>
                imageurl: {itemm.imageurl} 
                <br/>
                category: {itemm.category} 
                <br/>
                address: {itemm.address} 
                <br/>
                score: {itemm.score}                
                <br/>
                inlijsting: {itemm.inlijsting} 
                <br/>
                toplocation: {JSON.stringify(itemm.toplocation)}     
                <br/>
                <div id={index + " " + indexx} onClick={removealternative} style={{backgroundColor: "black", color: "white", marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 3, width: "fit-content", cursor: "pointer"}}>
                remove    
                </div>
                </div>
            ))} 

                <div>
                <div id={index + " addbutton"} onClick={addalternative} style={{fontSize: 30, backgroundColor: "black", color: "white", marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 3, width: "fit-content", cursor: "pointer"}}>
                add +  
                </div>
                <div style={{backgroundColor: "rgb(34, 34, 34)", color: "white", marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 0, paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5, borderRadius: 3, width: "fit-content"}}>
                Title
                </div>
                <input id={index + " title"} type="text"/>
                <br/>
                <div style={{backgroundColor: "rgb(34, 34, 34)", color: "white", marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 0, paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5, borderRadius: 3, width: "fit-content"}}>
                Website
                </div>
                <input id={index + " website"} type="text" />
                <br/>
                <div style={{backgroundColor: "rgb(34, 34, 34)", color: "white", marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 0, paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5, borderRadius: 3, width: "fit-content"}}>
                Imageurl
                </div>
                <input id={index + " imageurl"} type="text" />
                <br/>
                <div style={{backgroundColor: "rgb(34, 34, 34)", color: "white", marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 0, paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5, borderRadius: 3, width: "fit-content"}}>
                Category
                </div>
                <input id={index + " category"} type="text" />
                <br/>
                <div style={{backgroundColor: "rgb(34, 34, 34)", color: "white", marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 0, paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5, borderRadius: 3, width: "fit-content"}}>
                Address
                </div>
                <input id={index + " address"} type="text" />
                <br/>
                <div style={{backgroundColor: "rgb(34, 34, 34)", color: "white", marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 0, paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5, borderRadius: 3, width: "fit-content"}}>
                Score
                </div>
                <input id={index + " score"} type="text" />
                <br/>
                <div style={{backgroundColor: "rgb(34, 34, 34)", color: "white", marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 0, paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5, borderRadius: 3, width: "fit-content"}}>
                Inlijsting
                </div>
                <input id={index + " inlijsting"} type="text" />
                <br/>
                <div style={{backgroundColor: "rgb(34, 34, 34)", color: "white", marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 0, paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5, borderRadius: 3, width: "fit-content"}}>
                Toplocation
                </div>
                <input id={index + " toplocation"} type="text" />     

                <br/>
                <br/>
                <br/>           
                </div>

       

                </div>
            ))}    
            </div>
            : null
            }
            </div>
            )}

export default RecommendationsCategoriesAlternatives
