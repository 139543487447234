import React, { useState, useContext, useEffect, useRef } from "react";
import '../CSS/DesignSix/DesignSixPageFive.css';
import { APIdatacall } from "../AppValues.jsx";
import { designSixWishlistItemsArray } from "./DesignSixValues.jsx";
import NotFoundImageTwo from "../images/NotFoundImagetwo.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHeartCirclePlus, faHeartCircleCheck, faMapLocationDot, faEarthEurope, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import LinkCount from "../Home/LinkCount";

library.add(fab, faHeartCirclePlus, faHeartCircleCheck, faMapLocationDot, faEarthEurope, faTrashCan)

function DesignSixPageFive() {

    const [animationhappened, setAnimationhappened] = useState(false);
    // eslint-disable-next-line
    const [animationfifthpage, setAnimationfirstpage] = useState({left : {animation: 'x 0.3s', animationName: 'fadeInContent'}, right : {animation: 'x 0.4s', animationName: 'fadeInContent'}});


  // eslint-disable-next-line
  const { APIdata, setAPIdata } = useContext(APIdatacall);
 
  
    // eslint-disable-next-line
    const { designSixWishlistItems, setDesignSixWishlistItems } = useContext(designSixWishlistItemsArray);

    function animationchange () {
        if (animationhappened === false) {
            setAnimationhappened(true)
            setAnimationfirstpage({left : {animation: '', animationName: ''}, right : {animation: '', animationName: ''}})   
        }
    }

    var cityexplorertext;
    if (APIdata.styles.generalpage.cityexplorertext !== null){
        cityexplorertext = APIdata.styles.generalpage.cityexplorertext;
    } else {
        cityexplorertext = "Stadsontdekker " + APIdata.cityname;
    }

    var fifthpageheaderleft;
    if (APIdata.styles.pagefive.headerleft !== null){
        fifthpageheaderleft = APIdata.styles.pagefive.headerleft;
    } else {
        fifthpageheaderleft = "Jouw ultieme wishlist van " + APIdata.cityname;
    }

    var fifthpagesubheaderleft;
    if (APIdata.styles.pagefive.subheaderleft !== null){
        fifthpagesubheaderleft = APIdata.styles.pagefive.subheaderleft;
    } else {
        fifthpagesubheaderleft = "Dit zijn de plekken die jij aan je wishlist hebt toegevoegd. Mail ze naar jezelf of een vriend zodat je deze hotspots later kunt bezoeken.";
    }


    const [fontheightheader, setFontheightheader] = useState("");
    const [fontheightsubheader, setFontheightsubheader] = useState("");
    const [lineheightsubheader, setLineheightsubheader] = useState("");
    const [stadsontdekkersize, setStadsontdekkersize] = useState("");
    const [stadsontdekkermapsuntoldfontsize, setStadsontdekkermapsuntoldfontsize] = useState("");
    const [stadsontdekkermapsuntoldmargintop, setStadsontdekkermapsuntoldmargintop] = useState("");
    const [stadsontdekkertextmargintop, setStadsontdekkertextmargintop] = useState("");

    const [wishlistheaderrightfontsize, setWishlistheaderrightfontsize] = useState("");
    const [mailingheaderrightfontsize, setMailingheaderrightfontsize] = useState("");
    const [mailingsubheaderrightfontsize, setMailingsubheaderrightfontsize] = useState("");
    const [mailinginputbarrightfontsize, setMailinginputbarrightfontsize] = useState("");
    const [mailingbuttonrightfontsize, setMailingbuttonrightfontsize] = useState("");
    const [wishlistitemtitlerightfontsize, setWishlistitemtitlerightfontsize] = useState("");
    const [wishlistitemcategoryrightfontsize, setWishlistitemcategoryrightfontsize] = useState("");
    const [wishlistitemrouterightfontsize, setWishlistitemrouterightfontsize] = useState("");
    const [wishlistitemwebsiterightfontsize, setWishlistitemwebsiterightfontsize] = useState("");
    const [wishlistitembinrightfontsize, setWishlistitembinrightfontsize] = useState("");
    const [wishlistemptyrightfontsize, setWishlistemptyrightfontsize] = useState("");
    

//WIDTH FIX START
const [widthhappened, setWidthhappened] = useState(false)
function getWindowDimensions() {
const { innerWidth: width} = window;
return {
  width,
};
}
function handleResize() {
var newwidth = (getWindowDimensions());
if (newwidth.width >= 1024){
    setFontheightheader(APIdata.styles.pagethree.headerleftfontsizenormal);
    setFontheightsubheader(APIdata.styles.pagethree.subheaderleftfontsizenormal);
    setLineheightsubheader(APIdata.styles.pagethree.subheaderleftlineheightnormal);
    setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizenormal);
    setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizenormal);
    setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopnormal);

    setWishlistheaderrightfontsize(APIdata.styles.pagefive.wishlistheaderrightfontsizenormal);
    setMailingheaderrightfontsize(APIdata.styles.pagefive.mailingheaderrightfontsizenormal);
    setMailingsubheaderrightfontsize(APIdata.styles.pagefive.mailingsubheaderrightfontsizenormal);
    setMailinginputbarrightfontsize(APIdata.styles.pagefive.mailinginputbarrightfontsizenormal);
    setMailingbuttonrightfontsize(APIdata.styles.pagefive.mailingbuttonrightfontsizenormal);
    setWishlistitemtitlerightfontsize(APIdata.styles.pagefive.wishlistitemtitlerightfontsizenormal);
    setWishlistitemcategoryrightfontsize(APIdata.styles.pagefive.wishlistitemcategoryrightfontsizenormal);
    setWishlistitemrouterightfontsize(APIdata.styles.pagefive.wishlistitemrouterightfontsizenormal);
    setWishlistitemwebsiterightfontsize(APIdata.styles.pagefive.wishlistitemwebsiterightfontsizenormal);
    setWishlistitembinrightfontsize(APIdata.styles.pagefive.wishlistitembinrightfontsizenormal);
    setWishlistemptyrightfontsize(APIdata.styles.pagefive.wishlistemptyrightfontsizenormal);
} else if (newwidth.width >=720 && newwidth.width < 1024){
    setFontheightheader(APIdata.styles.pagethree.headerleftfontsizetablet);
    setFontheightsubheader(APIdata.styles.pagethree.subheaderleftfontsizetablet);
    setLineheightsubheader(APIdata.styles.pagethree.subheaderleftlineheighttablet);
    setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizetablet);
    setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizetablet);
    setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintoptablet);
    setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintoptablet);

    setWishlistheaderrightfontsize(APIdata.styles.pagefive.wishlistheaderrightfontsizetablet);
    setMailingheaderrightfontsize(APIdata.styles.pagefive.mailingheaderrightfontsizetablet);
    setMailingsubheaderrightfontsize(APIdata.styles.pagefive.mailingsubheaderrightfontsizetablet);
    setMailinginputbarrightfontsize(APIdata.styles.pagefive.mailinginputbarrightfontsizetablet);
    setMailingbuttonrightfontsize(APIdata.styles.pagefive.mailingbuttonrightfontsizetablet);
    setWishlistitemtitlerightfontsize(APIdata.styles.pagefive.wishlistitemtitlerightfontsizetablet);
    setWishlistitemcategoryrightfontsize(APIdata.styles.pagefive.wishlistitemcategoryrightfontsizetablet);
    setWishlistitemrouterightfontsize(APIdata.styles.pagefive.wishlistitemrouterightfontsizetablet);
    setWishlistitemwebsiterightfontsize(APIdata.styles.pagefive.wishlistitemwebsiterightfontsizetablet);
    setWishlistitembinrightfontsize(APIdata.styles.pagefive.wishlistitembinrightfontsizetablet);
    setWishlistemptyrightfontsize(APIdata.styles.pagefive.wishlistemptyrightfontsizetablet);
    } else if (newwidth.width < 720){        
    setFontheightheader(APIdata.styles.pagethree.headerleftfontsizemobile);
    setFontheightsubheader(APIdata.styles.pagethree.subheaderleftfontsizemobile);
    setLineheightsubheader(APIdata.styles.pagethree.subheaderleftlineheightmobile);
    setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizemobile);
    setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizemobile);
    setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopmobile);
    setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintopmobile);

    setWishlistheaderrightfontsize(APIdata.styles.pagefive.wishlistheaderrightfontsizemobile);
    setMailingheaderrightfontsize(APIdata.styles.pagefive.mailingheaderrightfontsizemobile);
    setMailingsubheaderrightfontsize(APIdata.styles.pagefive.mailingsubheaderrightfontsizemobile);
    setMailinginputbarrightfontsize(APIdata.styles.pagefive.mailinginputbarrightfontsizemobile);
    setMailingbuttonrightfontsize(APIdata.styles.pagefive.mailingbuttonrightfontsizemobile);
    setWishlistitemtitlerightfontsize(APIdata.styles.pagefive.wishlistitemtitlerightfontsizemobile);
    setWishlistitemcategoryrightfontsize(APIdata.styles.pagefive.wishlistitemcategoryrightfontsizemobile);
    setWishlistitemrouterightfontsize(APIdata.styles.pagefive.wishlistitemrouterightfontsizemobile);
    setWishlistitemwebsiterightfontsize(APIdata.styles.pagefive.wishlistitemwebsiterightfontsizemobile);
    setWishlistitembinrightfontsize(APIdata.styles.pagefive.wishlistitembinrightfontsizemobile);
    setWishlistemptyrightfontsize(APIdata.styles.pagefive.wishlistemptyrightfontsizemobile);
    }}
    
if ( widthhappened === false ) {
handleResize()
setWidthhappened(true)
}
useEffect(() => {
handleResize() 
window.addEventListener('resize', handleResize);
return () => window.removeEventListener('resize', handleResize);
});
//WIDTH FIX END

async function wishlistAPI (){
    console.log("hij gaat");
    var apistring = 'https://untoldapi.nl/wishlist?clientname=' + APIdata.clientname + '&id=0'
            for (let i = 0; i<designSixWishlistItems.length; i++){
            apistring = apistring + '&id=' + designSixWishlistItems[i].reco_id
        }
    // eslint-disable-next-line
    var messageawait = await fetch(apistring);
}

function savewishlist (){
    if (designSixWishlistItems.length > 0){
    wishlistAPI();
    }   
}

const shouldLog = useRef(true);
useEffect(()=>{
    if (shouldLog.current){
        shouldLog.current = false;
        savewishlist()
    }
})



const [queryy, setQueryy] = useState(1);
const [sendshow, setSendshow] = useState(false);


function WishListComponent () {

    const [query, setQuery] = useState("");

    async function mailList (){

        var apistring = 'https://untoldapi.nl/mail?clientname=' + APIdata.clientname + '&id=0'
            for (let i = 0; i<designSixWishlistItems.length; i++){
            apistring = apistring + '&id=' + designSixWishlistItems[i].reco_id
        }
        apistring = apistring + '&mailaddress=' + query
        // eslint-disable-next-line
        var messageawait = await fetch(apistring);        
        setQuery("");
        var test = queryy + 1;
        setQueryy(test);
        setSendshow(true);
        setTimeout(() => {
            setSendshow(false);
          }, 2700);
    }

    function inputHandler (event) {
        var indexvalue = event.target.value;
        setQuery(indexvalue);
        };

        

    function deletewishlistfunction (event){
        var deleteid = event.target.id.split('index=')[1];
        var oldlist = designSixWishlistItems;
        oldlist.splice(deleteid, 1);
        setDesignSixWishlistItems(oldlist);
        var test = queryy + 1;
        setQueryy(test)
    }


    return (
        <div className="design-four-page-five-wishlist-container">
        <div className="design-four-page-five-wishlist-header" 
            style={{
            fontFamily:  APIdata.styles.pagefive.wishlistheaderrightfont,
            fontSize: wishlistheaderrightfontsize
        }}>
        Mijn wishlist
        </div>

        {designSixWishlistItems.length > 0 ?
        null
        :
        <div className="design-four-page-five-wishlist-no-items" styles={{
            fontFamily : APIdata.styles.pagefive.wishlistemptyrightfont,
            fontSize: wishlistemptyrightfontsize
        }}>
        Je wishlist is leeg...
        </div>
        }

        {designSixWishlistItems.map((itemtitle, index)=>(
            <div className="design-four-page-five-wishlist-item-container" key={index}>
            <div className="design-four-page-five-wishlist-item-img-notfound" 
            style={{ backgroundImage: `url(${NotFoundImageTwo})` , backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}}>
            <div className="design-four-page-five-wishlist-item-img"
            style={{ backgroundImage: `url(${itemtitle.imageurl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
            </div>
            </div>
            <div className="design-four-page-five-wishlist-item-title-cat-container">
            <div className="design-four-page-five-wishlist-item-title"
            style={{
            fontFamily:  APIdata.styles.pagefive.wishlistitemtitlerightfont,
            fontSize: wishlistitemtitlerightfontsize
            }}>
            {itemtitle.title}
            </div>
            <div className="design-four-page-five-wishlist-item-category"
            style={{
            fontFamily:  APIdata.styles.pagefive.wishlistitemtcategoryrightfont,
            fontSize: wishlistitemcategoryrightfontsize
            }}>
            {itemtitle.category}
            </div>
            </div>
            <LinkCount origin="design-four-page-five-wishlist-item-route" href={"https://maps.google.com/?q="+itemtitle.address} target="_blank" rel="noreferrer" className="design-four-page-five-wishlist-item-route"
            style={{
            fontFamily:  APIdata.styles.pagefive.wishlistitemrouterightfont,
            color:  APIdata.styles.pagefive.wishlistitemrouterightcolor,
            fontSize: wishlistitemrouterightfontsize
            }}>
            <FontAwesomeIcon icon="fa-solid fa-map-location-dot" className="design-four-page-five-wishlist-item-route-icon"/>
            route
            </LinkCount>
            <LinkCount origin="design-four-page-five-wishlist-item-website" href={"https://" + itemtitle.website} target="_blank" rel="noreferrer" className="design-four-page-five-wishlist-item-website"
            style={{
            fontFamily:  APIdata.styles.pagefive.wishlistitemwebsiterightfont,
            color:  APIdata.styles.pagefive.wishlistitemwebsiterightcolor,
            fontSize: wishlistitemwebsiterightfontsize
            }}>
            <FontAwesomeIcon icon="fa-solid fa-earth-europe" className="design-four-page-five-wishlist-item-website-icon"/>
            website
            </LinkCount>
            <div id={"index="+index} onClick={deletewishlistfunction} className="design-four-page-five-wishlist-item-bin"
            style={{
            fontFamily:  APIdata.styles.pagefive.wishlistitembinrightfont,
            color:  APIdata.styles.pagefive.wishlistitembinrightcolor,
            fontSize: wishlistitembinrightfontsize
            }}>
            <FontAwesomeIcon icon="fa-solid fa-trash-can" className="design-four-page-five-wishlist-item-bin-icon"/>
            verwijder
            </div>
            </div>
                

        ))} 
                

        
        {designSixWishlistItems.length > 0 ?
            <div>
            <div className="design-four-page-five-wishlist-mailheader"
            style={{
            fontFamily:  APIdata.styles.pagefive.mailingheaderrightfont,
            fontSize: mailingheaderrightfontsize
            }}>
            Mail de lijst naar jezelf
        </div>
        <div className="design-four-page-five-wishlist-mailsubheader"
            style={{
            fontFamily:  APIdata.styles.pagefive.mailingsubheaderrightfont,
            fontSize: mailingsubheaderrightfontsize
            }}>
            Je ontvangt van ons een mailtje met alle informatie van jouw hotspots.
        </div>
        <input className="design-four-page-five-wishlist-inputmail" 
            placeholder= 'e-mailadres' 
            value={query}
            onChange={inputHandler}
            style={{
            fontFamily:  APIdata.styles.pagefive.mailinginputbarrightfont,
            fontSize: mailinginputbarrightfontsize,
            type: "email"
            }}>
        
        </input>
            <div className="design-four-page-five-wishlist-mail-button" onClick={mailList}
            style={{
            fontFamily:  APIdata.styles.pagefive.mailingbuttonrightfont,
            fontSize: mailingbuttonrightfontsize,
            backgroundColor: APIdata.styles.pagefive.mailingbuttonrightcolor
            }}>
            Lijst mailen
            </div>
            </div>
        :
        null
        }
        </div>
    )
}


function MailSendComponent () {



    return (
        <div>
        { sendshow === true ?
        <div>
        <div className="design-four-page-five-mailsend-background">
        </div> 
        <div className="design-four-page-five-mailsend-container">
        <div className="design-four-page-five-mailsend-left">
        </div>
        <div className="design-four-page-five-mailsend-right">
        </div>
        <div className="design-four-page-five-mailsend-text">
        Verzonden!
        </div>
        </div>
        </div>
        :
        null
        } 
        </div>      
    )}


    return (
        <div className="design-four-page-five-body" onClick={animationchange}>
        <div className="design-four-page-five-left-body"
        style={{
            backgroundColor: APIdata.styles.generalpage.leftcolor,
        }}>

        <div className="design-four-page-five-left-cityexplorer"
        style={{
            marginTop: APIdata.styles.generalpage.bartopheight,
            paddingTop: APIdata.styles.generalpage.citydiscoveryheight
        }}>
        <div className="design-four-page-five-left-cityexplorer-logo">
        <img src={require("../images/maps-untold-logo-small.png")} alt="logo" className="design-four-page-five-left-cityexplorer-logo-image"/>
        </div>
        <div className="design-four-page-five-left-cityexplorer-mapsuntold">
        <div className="design-four-page-five-left-cityexplorer-mapsuntold-text"
        style={{
            fontFamily: APIdata.styles.generalpage.stadsontdekkermapsuntoldfont,
            fontSize: stadsontdekkermapsuntoldfontsize,
            marginTop: stadsontdekkermapsuntoldmargintop
        }}>
        Maps Untold
        </div>
        </div>
        <div className="design-four-page-five-left-cityexplorer-line">
        </div>
        <div className="design-four-page-five-left-cityexplorer-city">
        <div className="design-four-page-five-left-cityexplorer-city-text"
        style={{
            fontFamily: APIdata.styles.generalpage.stadsontdekkertextfont,
            color: APIdata.styles.generalpage.colorone,
            fontSize: stadsontdekkersize,
            marginTop: stadsontdekkertextmargintop
        }}>
        {cityexplorertext}
        </div>
        </div>
        </div>

        <div className="design-four-page-five-left-header" 
        style={{
            fontFamily:  APIdata.styles.generalpage.headerleftfont,
            fontSize: fontheightheader,
            color: APIdata.styles.generalpage.headerleftcolor, 
            animation: animationfifthpage.left.animation,
            animationName: animationfifthpage.left.animationName,
        }}>
        {fifthpageheaderleft}
        </div>
        <div className="design-four-page-five-left-subheader"
        style={{
            fontFamily:  APIdata.styles.generalpage.subheaderleftfont,
            fontSize: fontheightsubheader,
            color: APIdata.styles.generalpage.subheaderleftcolor,
            lineHeight: lineheightsubheader,  
            animation: animationfifthpage.left.animation,
            animationName: animationfifthpage.left.animationName,

        }}>
        {fifthpagesubheaderleft}
        </div>
        </div>
        <div className="design-four-page-five-right-body"
        style={{
            backgroundColor: APIdata.styles.generalpage.rightcolor,
            paddingBottom: 100,
 }}>
        <div className="design-four-page-five-right-first-container" style={{
            marginTop: APIdata.styles.pagefour.rightmargintop,
            paddingBottom: 150,
    }}>

    

    <WishListComponent/>
    
        </div>
    
        </div>
        <MailSendComponent/>
        </div>
    )
}

export default DesignSixPageFive;
