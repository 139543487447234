import React, { useContext }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsExcludedLocations () {

    const { clientdata, setClientdata } = useContext(clientdatacontext);

    async function excludedlocationsfunction (event){
        var titleaddresssplit = event.target.id.split(" ");
        var action = titleaddresssplit[titleaddresssplit.length - 1];

        if (action === "add"){
            // eslint-disable-next-line
            var locationid = event.target.id.slice(0,-4)
            // eslint-disable-next-line
            // const urlexcludedlocationnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=excludedLocations&titleaddress=' + titleaddress + '&action=add' + '&apikey=' + clientdata.apikey);
            // const urlexcludedlocationnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=excludedLocations&titleaddress=' + titleaddress + '&action=add&apikey=' + clientdata.apikey);
            // const urlexcludedlocationnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=excludedLocations&locationid=' + locationid + '&action=add&apikey=' + clientdata.apikey);
            const urlexcludedlocationnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=excludedLocations&locationid=' + locationid + '&action=add&apikey=' + clientdata.apikey);
            // eslint-disable-next-line
            var urlexcludedlocationwithoutrecos = await urlexcludedlocationnojson.json();
            // eslint-disable-next-line
            var urlexcludedlocation = {clientname: urlexcludedlocationwithoutrecos.clientname, city: urlexcludedlocationwithoutrecos.city, apikey: urlexcludedlocationwithoutrecos.apikey, cityname: urlexcludedlocationwithoutrecos.cityname, excludedlocations: urlexcludedlocationwithoutrecos.excludedlocations, websiteChanges: urlexcludedlocationwithoutrecos.websiteChanges, imageChanges: urlexcludedlocationwithoutrecos.imageChanges, topChanges: urlexcludedlocationwithoutrecos.topChanges, inlijstingChanges: urlexcludedlocationwithoutrecos.inlijstingChanges, lengthRecos: urlexcludedlocationwithoutrecos.lengthRecos, categories: urlexcludedlocationwithoutrecos.categories, minimalrecos: urlexcludedlocationwithoutrecos.minimalrecos, status: urlexcludedlocationwithoutrecos.status, cityrecos: clientdata.cityrecos}
            setClientdata(urlexcludedlocation)
        } else if (action === "delete"){
            // eslint-disable-next-line
            var locationid = event.target.id.slice(0,-7)
            // eslint-disable-next-line
            // const urlexcludedlocationnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=excludedLocations&titleaddress=' + titleaddress + '&action=delete' + '&apikey=' + clientdata.apikey);
            // const urlexcludedlocationnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=excludedLocations&titleaddress=' + titleaddress + '&action=delete&apikey=' + clientdata.apikey);
            // const urlexcludedlocationnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=excludedLocations&locationid=' + locationid + '&action=delete&apikey=' + clientdata.apikey);
            const urlexcludedlocationnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=excludedLocations&locationid=' + locationid + '&action=delete&apikey=' + clientdata.apikey);
            // eslint-disable-next-line
            var urlexcludedlocationwithoutrecos = await urlexcludedlocationnojson.json();
            // eslint-disable-next-line
             var urlexcludedlocation = {clientname: urlexcludedlocationwithoutrecos.clientname, city: urlexcludedlocationwithoutrecos.city, apikey: urlexcludedlocationwithoutrecos.apikey, cityname: urlexcludedlocationwithoutrecos.cityname, excludedlocations: urlexcludedlocationwithoutrecos.excludedlocations, websiteChanges: urlexcludedlocationwithoutrecos.websiteChanges, imageChanges: urlexcludedlocationwithoutrecos.imageChanges, topChanges: urlexcludedlocationwithoutrecos.topChanges, inlijstingChanges: urlexcludedlocationwithoutrecos.inlijstingChanges, lengthRecos: urlexcludedlocationwithoutrecos.lengthRecos, categories: urlexcludedlocationwithoutrecos.categories, minimalrecos: urlexcludedlocationwithoutrecos.minimalrecos, status: urlexcludedlocationwithoutrecos.status, cityrecos: clientdata.cityrecos}
            setClientdata(urlexcludedlocation)
        }}

    return (
        <div className="login-recommendations-categories-excluded-locations-container">

            <div className="login-recommendations-categories-excluded-locations-excluded-locations-list-header">Items in excluded locations list</div>

            {clientdata.cityrecos.map((item, index)=>(
            <div style={{ display: "inline-block"}} key={index}>
            {clientdata.excludedlocations.includes(item._id) ? 
            <div className="login-recommendations-categories-location-container">
            <img src= {item.imageURL} alt="img" style={{width: 100, height:100}}/>
            <div className="login-recommendations-categories-location-text">{item.title.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.city.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.address.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.website.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.category.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-button" id={item._id + " delete"} onClick={excludedlocationsfunction}> delete from list </div> 
            </div> : null}
            </div>
            ))}

            <div className="login-recommendations-categories-excluded-locations-all-locations-list-header">Items not in excluded locations list</div>

            {clientdata.cityrecos.map((item, index)=>(
            <div style={{ display: "inline-block"}} key={(index)}>
            {clientdata.excludedlocations.includes(item._id) ? 
            null :
            <div className="login-recommendations-categories-location-container">
            <img src= {item.imageURL} alt="img" style={{width: 100, height:100}}/>
            <div className="login-recommendations-categories-location-text">{item.title.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.city.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.address.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.website.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.category.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-button" id={item._id + " add"} onClick={excludedlocationsfunction}> add to list </div> 
            </div>}
            </div>
            ))}

        </div>
    )
}

export default RecommendationsExcludedLocations
