import React, { useContext }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsCategoriesSubCategories () {

// eslint-disable-next-line
    const { clientdata, setClientdata } = useContext(clientdatacontext);


    async function handleclickaddsubcategory (event) {
        var inbetweenid = event.target.id.split(" ")
        var indexofparent = inbetweenid[1];
        var newsubcategory = document.getElementById("inputnewsubcategory " + inbetweenid[1]).value
        document.getElementById("inputnewsubcategory " + inbetweenid[1]).value = '';
        
        
        // const urlsubcategoriesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=subcategories&actionsubcategoryaction=add&indexofparent=' + indexofparent + '&newsubcategory=' + newsubcategory + '&apikey=' + clientdata.apikey)
        const urlsubcategoriesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=subcategories&actionsubcategoryaction=add&indexofparent=' + indexofparent + '&newsubcategory=' + newsubcategory + '&apikey=' + clientdata.apikey)
            var urlsubcategorieswithoutrecos = await urlsubcategoriesnojson.json();
             var urlsubcategories = {clientname: urlsubcategorieswithoutrecos.clientname, city: urlsubcategorieswithoutrecos.city, apikey: urlsubcategorieswithoutrecos.apikey, cityname: urlsubcategorieswithoutrecos.cityname, excludedlocations: urlsubcategorieswithoutrecos.excludedlocations, websiteChanges: urlsubcategorieswithoutrecos.websiteChanges, imageChanges: urlsubcategorieswithoutrecos.imageChanges, topChanges: urlsubcategorieswithoutrecos.topChanges, inlijstingChanges: urlsubcategorieswithoutrecos.inlijstingChanges, lengthRecos: urlsubcategorieswithoutrecos.lengthRecos, categories: urlsubcategorieswithoutrecos.categories, minimalrecos: urlsubcategorieswithoutrecos.minimalrecos, status: urlsubcategorieswithoutrecos.status, cityrecos: clientdata.cityrecos}
            setClientdata(urlsubcategories)
    }

    async function handleclickdeletesubcategory (event) {
        var inbetweenid = event.target.id.split(" ")
        var indexofparent = inbetweenid[0];
        var indexofsub = inbetweenid[1];

        // const urlsubcategoriesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=subcategories&actionsubcategoryaction=delete&indexofparent=' + indexofparent + '&newsubcategory=' + indexofsub + '&apikey=' + clientdata.apikey)
        const urlsubcategoriesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=subcategories&actionsubcategoryaction=delete&indexofparent=' + indexofparent + '&newsubcategory=' + indexofsub + '&apikey=' + clientdata.apikey)
        var urlsubcategorieswithoutrecos = await urlsubcategoriesnojson.json();
         var urlsubcategories = {clientname: urlsubcategorieswithoutrecos.clientname, city: urlsubcategorieswithoutrecos.city, apikey: urlsubcategorieswithoutrecos.apikey, cityname: urlsubcategorieswithoutrecos.cityname, excludedlocations: urlsubcategorieswithoutrecos.excludedlocations, websiteChanges: urlsubcategorieswithoutrecos.websiteChanges, imageChanges: urlsubcategorieswithoutrecos.imageChanges, topChanges: urlsubcategorieswithoutrecos.topChanges, inlijstingChanges: urlsubcategorieswithoutrecos.inlijstingChanges, lengthRecos: urlsubcategorieswithoutrecos.lengthRecos, categories: urlsubcategorieswithoutrecos.categories, minimalrecos: urlsubcategorieswithoutrecos.minimalrecos, status: urlsubcategorieswithoutrecos.status, cityrecos: clientdata.cityrecos}
        setClientdata(urlsubcategories)
    }



    return (
        <div className="login-recommendations-categories-categories-display-container">
    
            <div className="login-recommendations-categories-categories-overview-header">Subcategories</div>
            { clientdata.categories.length > 0 ?
            <div>
            {clientdata.categories.map((item, index)=>(
                <div key={index} className="login-recommendations-categories-categories-parentcategory-container">
                <div key={index} className="login-recommendations-categories-categories-parentcategory" style={{marginTop: 10}}>
                {item.parentcategory}
                </div>
                { item.subcategories.length > 0 ?
                <div>
                <div className="login-recommendations-categories-subcategories-overview-add-remove-header">add</div>
                <input id={"inputnewsubcategory " + index}></input>
                <div className="login-recommendations-categories-subcategories-overview-add-button" onClick={handleclickaddsubcategory} id={"buttonnewsubcategory " + index}>add</div>
                <div className="login-recommendations-categories-subcategories-overview-add-remove-header">remove</div>
             {item.subcategories.map((itemtwo, indexx)=>(
                <div key={indexx+1000} className="login-recommendations-categories-categories-subcategory-container">
                <div onClick={handleclickdeletesubcategory} id={index + " " + indexx} className="login-recommendations-categories-categories-subcategory">
                {itemtwo.subcategory}
                </div>
                </div>
                ))}
                </div>
            : <div>
                <div className="login-recommendations-categories-subcategories-overview-add-remove-header">add</div>
                <input id={"inputnewsubcategory " + index}></input>
                <div className="login-recommendations-categories-subcategories-overview-add-button" onClick={handleclickaddsubcategory} id={"buttonnewsubcategory " + index}>add</div>
                </div>
            }
            <div style={{backgroundColor: "gray", height: 15, marginBottom: 30, marginTop: 70}}></div>
                </div>
            ))}    
            </div>
            : null
            }
            </div>
            )}

export default RecommendationsCategoriesSubCategories
