import React, { useContext, useState }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsWebsites () {

    const { clientdata, setClientdata } = useContext(clientdatacontext);
    const [ websiteurlinput, setWebsiteurlinput ] = useState("");
    
    
    var listoflocationsinwebsitechangeslist = [];
        for (let i = 0; i < clientdata.websiteChanges.length; i++) {
            listoflocationsinwebsitechangeslist.push(clientdata.websiteChanges[i].locationid)
        }
    
    
        function inputhandler (event) {
    
            var indexvalue = event.target.value;
            setWebsiteurlinput(indexvalue);
            };
    
    
        async function websitechangesfunction (event){
            var titleaddresssplit = event.target.id.split(" ");
            var action = titleaddresssplit[titleaddresssplit.length - 1];
    
            if (action === "add"){
                // eslint-disable-next-line
                var locationid = event.target.id.slice(0,-4)
                // eslint-disable-next-line
                // const urlwebsiteChangesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=websiteChanges&titleaddress=' + titleaddress + '&action=add&websiteurl=' + websiteurlinput + '&apikey=' + clientdata.apikey);
                // const urlwebsiteChangesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=websiteChanges&titleaddress=' + titleaddress + '&action=add&websiteurl=' + websiteurlinput + '&apikey=' + clientdata.apikey);
                // const urlwebsiteChangesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=websiteChanges&locationid=' + locationid + '&action=add&websiteurl=' + websiteurlinput + '&apikey=' + clientdata.apikey);
                const urlwebsiteChangesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=websiteChanges&locationid=' + locationid + '&action=add&websiteurl=' + websiteurlinput + '&apikey=' + clientdata.apikey);
                // eslint-disable-next-line
                setWebsiteurlinput("");
                var urlwebsiteChangeswithoutrecos = await urlwebsiteChangesnojson.json();
                // eslint-disable-next-line
                 var urlwebsiteChanges = {clientname: urlwebsiteChangeswithoutrecos.clientname, city: urlwebsiteChangeswithoutrecos.city, apikey: urlwebsiteChangeswithoutrecos.apikey, cityname: urlwebsiteChangeswithoutrecos.cityname, excludedlocations: urlwebsiteChangeswithoutrecos.excludedlocations, websiteChanges: urlwebsiteChangeswithoutrecos.websiteChanges, imageChanges: urlwebsiteChangeswithoutrecos.imageChanges, topChanges: urlwebsiteChangeswithoutrecos.topChanges, inlijstingChanges: urlwebsiteChangeswithoutrecos.inlijstingChanges, lengthRecos: urlwebsiteChangeswithoutrecos.lengthRecos, categories: urlwebsiteChangeswithoutrecos.categories, minimalrecos: urlwebsiteChangeswithoutrecos.minimalrecos, status: urlwebsiteChangeswithoutrecos.status, cityrecos: clientdata.cityrecos}
                setClientdata(urlwebsiteChanges)
            } else if (action === "delete"){
                // eslint-disable-next-line
                var locationid = event.target.id.slice(0,-7)
                // eslint-disable-next-line
                // const urlwebsiteChangesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=websiteChanges&titleaddress=' + titleaddress + '&action=delete' + '&apikey=' + clientdata.apikey);
                // const urlwebsiteChangesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=websiteChanges&titleaddress=' + titleaddress + '&action=delete&apikey=' + clientdata.apikey);
                // const urlwebsiteChangesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=websiteChanges&locationid=' + locationid + '&action=delete&apikey=' + clientdata.apikey);
                const urlwebsiteChangesnojson = await fetch('https://untoldapi.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=websiteChanges&locationid=' + locationid + '&action=delete&apikey=' + clientdata.apikey);
                // eslint-disable-next-line
                setWebsiteurlinput("");
                // eslint-disable-next-line
                var urlwebsiteChangeswithoutrecos = await urlwebsiteChangesnojson.json();
                console.log(urlwebsiteChangeswithoutrecos);
                // eslint-disable-next-line
                 var urlwebsiteChanges = {clientname: urlwebsiteChangeswithoutrecos.clientname, city: urlwebsiteChangeswithoutrecos.city, apikey: urlwebsiteChangeswithoutrecos.apikey, cityname: urlwebsiteChangeswithoutrecos.cityname, excludedlocations: urlwebsiteChangeswithoutrecos.excludedlocations, websiteChanges: urlwebsiteChangeswithoutrecos.websiteChanges, imageChanges: urlwebsiteChangeswithoutrecos.imageChanges, topChanges: urlwebsiteChangeswithoutrecos.topChanges, inlijstingChanges: urlwebsiteChangeswithoutrecos.inlijstingChanges, lengthRecos: urlwebsiteChangeswithoutrecos.lengthRecos, categories: urlwebsiteChangeswithoutrecos.categories, minimalrecos: urlwebsiteChangeswithoutrecos.minimalrecos, status: urlwebsiteChangeswithoutrecos.status, cityrecos: clientdata.cityrecos}
                setClientdata(urlwebsiteChanges)
            }}
    
return (    

    <div className="login-recommendations-categories-website-changes-locations-container">

        <div className="login-recommendations-categories-locations-website-changes-locations-list-included-header">Items in changed website list</div>

        {clientdata.cityrecos.map((item, index)=>(

        <div style={{ display: "inline-block"}} key={index}>
        {listoflocationsinwebsitechangeslist.includes(item._id) ? 

        <div className="login-recommendations-categories-location-container">
        <img src= {item.imageURL} alt="img" style={{width: 100, height:100}}/>
        <div className="login-recommendations-categories-location-text">{item.title.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.city.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.address.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{clientdata.websiteChanges[clientdata.websiteChanges.findIndex(x => x.locationid === item._id)].website.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.category.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-button" id={item._id + " delete"} onClick={websitechangesfunction}> delete from list </div> 
        </div> 
        : null }
        </div>
        ))}

        <div className="login-recommendations-categories-locations-website-changes-locations-list-excluded-header">Items not in website list</div>
        {clientdata.cityrecos.map((item, index)=>(
        <div style={{ display: "inline-block"}} key={index}>
        {listoflocationsinwebsitechangeslist.includes(item._id) ? 
        null :
        <div className="login-recommendations-categories-location-container">
        <img src= {item.imageURL} alt="img" style={{width: 100, height:100}}/>
        <div className="login-recommendations-categories-location-text">{item.title.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.city.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.address.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.website.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.category.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-button" id={item._id + " add"} onClick={websitechangesfunction}> add to list </div> 
        </div> }
        </div>
        ))}

        <input className="login-recommendations-categories-locations-website-changes-inputbar"
        onChange = {inputhandler}
        value={websiteurlinput}
        placeholder= {"Paste the link here and press the 'add to list' button"}
        autocomplete="off" autocorrect="off"
        ></input>

    </div>
)
}

export default RecommendationsWebsites
